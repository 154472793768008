@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700;800&display=swap");

@layer base {
  body {
    /* font-family: "Quicksand", sans-serif; */
    font-family: "Inter", sans-serif;
    margin: 0px;
    padding: 0px !important;
    font-weight: normal;
    font-style: normal;
    /* @apply bg-gray-50; */
  }

  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }

  h4 {
    @apply text-base;
  }

  h5 {
    @apply text-sm;
  }

  h6 {
    @apply text-xs;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Quicksand", sans-serif;
}

@layer utilities {
}

iframe body {
  padding: 0px !important;
}

input:checked + .toggle-bg {
  background: #51ad8d !important;
  border-color: #51ad8d !important;
}

/* Apply styles to checked checkboxes */
[type="checkbox"]:checked {
  background-color: black;
  outline: none;
}

/* Apply styles to focused checkboxes */
[type="checkbox"]:focus {
  outline: none;
  border: none;
  box-shadow: none;
  background-color: black;
}

/* Apply styles to all checkboxes, including unchecked ones */
[type="checkbox"] {
  border: 1px solid gray;
}

/* Apply a white background to unchecked checkboxes */
[type="checkbox"]:not(:checked) {
  background-color: white;
  border: 1px solid gray;
}

ul.numeric-decimal-style {
  counter-reset: section;
  list-style-type: none;
  margin-bottom: 0px;
}

ul.numeric-decimal-style ul {
  margin-bottom: 30px;
}

ul.numeric-decimal-style li {
  padding-left: 30px;
  margin-top: 10px;
  position: relative;
}

ul.numeric-decimal-style ul li {
  padding-left: 35px;
}

ul.numeric-decimal-style > li {
  font-size: 15px;
  font-weight: 600;
}

ul.numeric-decimal-style ul {
  counter-reset: subsection;
  color: #555;
  font-weight: 400;
  font-size: 16px;
}

ul.numeric-decimal-style li:before {
  counter-increment: section;
  content: counter(section) ". ";
  position: absolute;
  left: 0;
  top: 0;
}

ul.numeric-decimal-style ul li:before {
  counter-increment: subsection;
  content: counter(section) "." counter(subsection) " ";
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 500;
  color: #000;
  font-size: 14px;
}

ul.numeric-decimal-style .text-base {
  color: #555;
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 30px;
  padding-left: 30px;
}

ul.numeric-decimal-style ul ul li:before {
  content: none;
}

ul.numeric-decimal-style ul ul li {
  list-style: disc;
  padding-left: 0;
}

.scrollbarthin::-webkit-scrollbar {
  width: "1px";
}

.scrollable::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}
/* ========== datepicker */
/* ========== datepicker */
/* ========== datepicker */
/* ========== datepicker */
/* ========== datepicker */
/* ========== datepicker */
/* ========== datepicker */
/* ========== datepicker */

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__day.react-datepicker__day--keyboard-selected {
  border: none;
  border-radius: 70px;
  background-color: var(--dark);
  color: var(--white);
}

.react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
  border: none;
  border-radius: 7px;
  background-color: var(--dark);
  color: var(--white);
}

.react-datepicker-popper .react-datepicker__navigation {
  padding-top: 12px;
  color: #ff7878;
}

.react-datepicker {
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.189);
  border: none !important;
}

.react-datepicker__header {
  border-bottom: solid 5px var(--light) !important;
  background: white !important;
}

.react-datepicker__current-month {
  color: #344054 !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-family: "Inter", sans-serif;
}

.react-datepicker__day.react-datepicker__day--today {
  border-radius: 7px;
  border: solid 2px var(--brand) !important;
  background-color: white !important;
  /* color: var(--dark) !important; */
  width: 30px;
  height: 30px;
}

.react-datepicker__day.react-datepicker__day--selected {
  border: none;
  border-radius: 50%;
  background-color: #344054;
  color: white;
}
.react-datepicker__day.react-datepicker__day {
  font-size: 13px;
}
.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  border: none;
  border-radius: 7px !important;
  background-color: var(--brand) !important;
  color: var(--dark) !important;
}
/* month picker */

.react-datepicker__month-container {
  /* background-color: #081833; */
  color: black;
  font-size: 1rem;
  padding: 1rem;
}
.react-datepicker__month {
  padding: 1rem 0;
  margin: 2rem;
}
.react-datepicker__year-text {
  color: #344054;
}
.react-datepicker__month-text {
  display: inline-block;
  width: 5rem;
  margin: 0.5rem;
  font-size: 0.7rem;
  padding: 0.4rem;
  color: #344054;
}
.react-datepicker__year-text {
  color: #344054;
}

.react-datepicker__month-text:hover {
  background-color: #ff7878;
}

.react-datepicker__year-text.react-datepicker__year--selected {
  border: none;
  background-color: #f2f4f7;
  color: #344054;
}
div.is-disabled {
  pointer-events: none;
  opacity: 0.7;
}
.description-pre-wrap {
  white-space: pre-wrap;
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Customize the label (the container) */
.radioContainer {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-bottom: 24px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 0;
}

/* Hide the browser's default radio button */
.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  border: 1px solid #d1d1db;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

/* When the radio button is checked, add a blue background */
.radioContainer input:checked ~ .checkmark {
  background-color: #ffaeae;
  border: none;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .checkmark:after {
  top: 6px;
  left: 6px;
  width: 12px;
  height: 12px;
  border-radius: 45px;
  background: white;
}
/* .otp-input input::placeholder {
  color: #d0d5dd;
  font-size: 3.5rem;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
} */
.custom-date-picker {
  border-radius: 10px; /* Set the desired border radius */
  outline: none; /* Remove the outline */
  position: relative; /* Set the position to relative for pseudo-elements */
}

.custom-date-picker::before {
  content: "\f073"; /* Unicode for the date icon (adjust as needed) */
  font-family: "Font Awesome"; /* If you're using an icon library, specify the font family */
  position: absolute;
  top: 50%;
  left: 10px; /* Adjust the positioning of the icon */
  transform: translateY(-50%);
  pointer-events: none; /* Prevent the icon from being interactive */
}

.disabled {
  color: gray; /* Style for disabled dates */
}
.custom-datepicker-wrapper
  .react-datepicker__input-container
  input::placeholder {
  text-align: center;
}
.custom-datepicker-wrapper .react-datepicker__input-container input {
  text-align: center;
  border: 2px solid #9c9caf;
}

.custom-datepicker-wrapper .react-datepicker-popper {
  z-index: 50;
}

.newDob .react-datepicker__year-dropdown {
  font-size: 0.8rem;
  margin-top: 0.4rem;
}

.newDob .react-datepicker__year-dropdown .react-datepicker__year-option {
  padding: 0.3rem;
}

.newDob .react-datepicker__year-dropdown .react-datepicker__year-option:hover {
  background-color: #f2f2f2;
}

.react-select-outline__input:focus {
  box-shadow: none;
}
/* .menudatepicker{
  width: 150px; 
} */
/* CSS in your stylesheet or CSS module */
.selected {
  background-color: #F0F0F0;
  padding: 5px 10px ;
  border-radius: 10px;
}
